import React, { useState, useEffect } from 'react';
import './ViewJob.css';
import { useNavigate } from 'react-router-dom';
import {Table, Steps, Alert, Button, message, Tag, Modal, Upload, Input  } from 'antd';
import { CloseCircleOutlined, CheckCircleOutlined, SaveOutlined, UserAddOutlined, DownloadOutlined,DeleteOutlined, PoweroffOutlined } from '@ant-design/icons';
import axios from '../axiosConfig';
import { customFetch } from '../customFetch';
import debounce from 'lodash/debounce';

const { Step } = Steps;

// const PY_API_URL = "https://aztowaetopybackend.azurewebsites.net";
// const PY_API_URL = "http://localhost:5000/";
const PY_API_URL = 'https://zwewaetopybe.azurewebsites.net';

function ViewJob({ job, closePopup  }) {
    const { TextArea } = Input;
    const userName = localStorage.getItem('userName');
    const userRole = localStorage.getItem('userRole');
    const [attachedFiles, setAttachedFiles] = useState(job.files);
    const [recentAttachedFile, setRecentAttachedFile] = useState(null);
    const [attachedResults, setAttachedResults] = useState(job.resultsAndReportsFiles);
    const [recentAttachedResults, setRecentAttachedResults] = useState(null);
    const [approvedReports, setApprovedReports] = useState([]);
    const [tirText, setTirText] = useState(job.tirReports ? job.tirReports : '');
    const [loadings, setLoadings] = useState([false]);
    // const projectLeader = job.projectLeader;
    // console.log("Job object:", job);
    // console.log(approvedReports);
    
    const navigate = useNavigate();
    
    const [nodes, setNodes] = useState(job.state ? (job.state.nodes ? job.state.nodes : []):[]);
    const [quotation, setQuotation] = useState({
        "quotationJobName": '',
        "quotationStartDate": '',
        'quotationEndDate': '',
        'hourlyCost': '',
        'etoHours': '',
        'etoCost': '',
        'outsourcingCost': '',
        'fixtureCost': '',
        'totalCost': '',
        'outsourcingDetails': '',
        'quotationOnCompletion': '',
        'quotationComments': ''
    });    
    // console.log(quotation);
    const [activeTab, setActiveTab] = useState(0);
    const [sectionVisibility, setSectionVisibility] = useState({
        quote: false,
        projectLeader: false,
        termsAndConditions: false,
        jobHistory: false,
        additionalFiles: false,
        resultsandreports: false,
        jobDescription: false
    });

    const [jobHistory, setJobHistory] = useState([]);
    const [error, setError] = useState(null); 
    const statusDetailsMap = {
        1: 'Job Saved by Originator',
        2: 'Job Submitted by Originator',
        3: 'Job Assigned by PL',
        4: 'Quotation Submitted by PL',
        5: 'Job Returned from PL',
        6: 'Job Approved by CCM',
        7: 'Job Rejected by CCM',
        8: 'Job Complete',
        9: 'Pending Assignment', 
        10: 'Pending CCM Approval'
    };

    async function trackError(errorCode, errorMessage, apiCall) {
        try {
          const response = await axios.post(`${PY_API_URL}/api/log_error`, {
            error_code: errorCode,
            error_message: errorMessage,
            api_call: apiCall,
            userName: userName,
          });
          console.log('Response:', response.data);
          return response.data;
        } catch (error) {
          console.error('Error tracking button click:', error);
          throw error;
        }
      }
    
    useEffect(() => {
        const fetchJobHistory = async () => {
            try {
                const response = await customFetch(`${PY_API_URL}/api/job-history/${job.jobOrderID}`);
                if (!response.ok) {
                    throw new Error(`Failed to fetch job history: ${response.statusText}`);
                }
                const text = await response.text(); 
                try {
                    const data = JSON.parse(text); 
                    const formattedData = data.map(entry => ({
                        ...entry,
                        title: statusDetailsMap[entry.StatusChange],
                        description: `${entry.Role}: ${entry.UserName}`,
                        time: new Date(entry.TimeStamp).toLocaleString(),
                    }));
                    setJobHistory(formattedData);
                    setError(null);
                } catch (err) {
                    throw new Error("The server's response was not in JSON format.");
                }
            } catch (error) {
                console.error("Error fetching job history:", error);
                setError(error.toString());
            }
        };
        if (job.jobOrderID) {
            fetchJobHistory();
        }
    }, [job.jobOrderID]);
      

    
    //gets the nodes
    useEffect(() => {
        customFetch(`${PY_API_URL}/api/get_nodes/${job.jobOrderID}`)
            .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
            })
            .then((data) => {
            // console.log(data);
            setNodes(data);
            })
            .catch((error) => {
            console.error('Error fetching data:', error);
            });
    }, []);

    const downloadFile = (file) => {
        customFetch(`${PY_API_URL}/api/download_file/${file}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob(); // Get the response as a Blob
            })
            .then((blob) => {
                // Create a link element
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = file;
    
                // Append the link to the body
                document.body.appendChild(a);
    
                // Simulate click
                a.click();
    
                // Remove the link
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };    

      const data = [];
    for (const node of nodes) {
        data.push({
            TestNumber: node.data.testNumber,
            TestName: node.data.testName,
            HoursEstimation: node.data.hoursEstimation,
            ExpenseEstimation: node.data.expenseEstimation,
        });
    }

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const toggleSection = (section) => {
        setSectionVisibility({
            quote: false,
            projectLeader: false,
            termsAndConditions: false,
            jobHistory: false,
            additionalFiles: false,
            resultsandreports: false,
            jobDescription: false,
            [section]: true // Only the clicked section will be true
        });
    };

    useEffect(() => {
          customFetch(`${PY_API_URL}/api/get_quotation/${job.jobOrderID}`)
            .then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then((data) => {
              setQuotation(data);
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
      }, []);


    const handleFileSelection = (event, type) => {
        const files = Array.from(event.target.files);
        if(type === 0){
            console.log("hehe")
            setAttachedFiles([...attachedFiles, ...files]);
            setRecentAttachedFile(files[files.length - 1]);
        } 
        else{
            console.log("hehehe")
            setAttachedResults([...attachedResults, ...files]);
            setRecentAttachedResults(files[files.length - 1]);
        }
    };

    const jobData = {
        'jobID' : job.jobOrderID,
        'file': recentAttachedFile,
        'results': recentAttachedResults,
    };

    const updateFiles = async () => {
        try {
            const response = await customFetch(`${PY_API_URL}/api/get_jobs`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            data.forEach((j) => {
                if (j.jobOrderID === job.jobOrderID) {
                    console.log(j.files);
                    setAttachedFiles(j.files);
                    setAttachedResults(j.resultsAndReportsFiles);
                };
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const formData = new FormData();
        Object.keys(jobData).forEach((key) => {
              formData.append(key, jobData[key]);
          });
        customFetch(`${PY_API_URL}/api/upload_file`, {
            method: 'POST',
            body: formData,
          })
            .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
            })
            .then((data) => {
                console.log('File saved successfully.', data);
                updateFiles();
            })
            .catch((error) => {
            console.error('Error fetching data:', error);
            });
    }, [recentAttachedFile]);

    useEffect(() => {
        const formData = new FormData();
        Object.keys(jobData).forEach((key) => {
              formData.append(key, jobData[key]);
          });
        customFetch(`${PY_API_URL}/api/upload_results_reports`, {
            method: 'POST',
            body: formData,
          })
            .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
            })
            .then((data) => {
                console.log('File saved successfully.', data);
                updateFiles();
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                // trackError(error.name || 'NETWORK_ERROR', error.message || 'Unknown error occurred during file upload', '/api/upload_results_reports');
            });
    }, [recentAttachedResults]);
      

    // Function to remove a file
    const removeFile = (index, file, type) => {
            if (type === 0){
                const updatedFiles = [...attachedFiles];
                updatedFiles.splice(index, 1);
                setAttachedFiles(updatedFiles);
            }
            else{
                const updatedFiles = [...attachedResults];
                updatedFiles.splice(index, 1);
                setAttachedResults(updatedFiles);
            }
            
            customFetch(`${PY_API_URL}/api/delete_file/${file}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob(); // Get the response as a Blob
            })
            .then((blob) => {
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    
    const handleTakeAssignment = (jobNum, name) => {
        customFetch(`${PY_API_URL}/api/take_assignment/data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ job_id: jobNum, name: name })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(() => {
            alert("Successfully taken the assignment!");
            // Redirects to /addJob with state
            navigate('/addJob', { state: { from: "viewJob", job: job } }); 
        })
        .catch(error => {
            console.error('Error taking assignment:', error);
        });
     };

     const showConfirm = (fileName) => {
        Modal.confirm({
          title: 'Are you sure you want to approve this document?',
          content: 'This action cannot be undone.',
          onOk() {
            handleReportApprove(fileName);
          },
          onCancel() {
            console.log('Inactivation cancelled');
          },
        });
      };
     
     const handleReportApprove = async (fileName) => {
        const reports = {
            reportName: fileName,
        };
      
        try {
          const response = await axios.post(
            `${PY_API_URL}/api/approveReportWithoutNodes`, 
            reports, 
            {
              headers: {
                'Content-Type': 'application/json', 
              },
            }
          );
      
          if (response.status === 200) {
            message.success("Report approved successfully");
            handleApprovedReports(job.jobOrderID);
            // fetchReportLogData(); // Refresh table data 
          } else {
            message.error("Failed to approve report");
          }
        } catch (error) {
          console.error("Error approving report:", error);
          message.error("Error approving report");
        }
      };

    useEffect(() => {
        handleApprovedReports(job.jobOrderID);
    }, []);

      const handleApprovedReports = (jobNum) => {
        customFetch(`${PY_API_URL}/api/getApprovedReports/${jobNum}`, {
          method: "GET",
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error("Network response was not ok.");
          })
          .then((data) => {
            setApprovedReports(data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      };
  
    const EnterFlowChart = () => {
    job['from'] = 'ViewJob';
    navigate('/flowchart', {state: job})
    };

    const updateTirs = async () => {
        try {
            const response = await customFetch(`${PY_API_URL}/api/get_jobs`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            data.forEach((j) => {
                if (j.jobOrderID === job.jobOrderID) {
                    setTirText(j.tirReports);
                };
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        updateTirs();
    }, []);

    const handleTirText = (text) => {
        setLoadings([true]);
      
        customFetch(`${PY_API_URL}/api/submitTirText/${job.jobOrderID}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ text }),
        })
          .then(response => response.json())
          .then(data => {
            console.log('Success:', data);
          })
          .catch(error => {
            console.error('Error:', error);
          })
          .finally(() => {
            setLoadings([false]);
          });
      };

    // useEffect(() => {
    //     handleTirText(tirText);
    //   }, [tirText]);
    
    return (
        <div>
            <div className="viewJob">
            <button className="close-button" onClick={closePopup}>X</button>
                <div className="section">
                {/* <Button className='assignment-btn' component={Link} to="/addjob">
                Take Assignment
                </Button> */}
                    <h3>JOB</h3>
                    <div className="job-description">
                        <p>Job Order Number: {job.jobOrderNumber}</p>
                        <p>Status: {job.status}</p>
                        <p>Assigned To: {job.projectLeader}</p>
                    </div>
                    <h3>GENERAL INFORMATION</h3>
                    <div className="general-information">
                        <p>Originator: {job.originator}</p>
                        <p>ETO Project: {job.jobName}</p>
                        <p>Creation Date: {job.creationDate}</p>
                        <p>Customer: {job.customer}</p>
                        <p>Division Name: {job.division}</p>
                        <p>Job Type: {job.jobType} </p>
                        <p>Job Group: {job.jobGroup} </p>
                        <div className='cost-center'>
                            <p>Cost Center: {job.costCenter}</p>
                            <p>Cost Center Description: {job.costCenterDescription}</p>
                            <p>Cost Center Manager: {job.costCenterManager}</p>
                            <p>Cost Center Backup Approver: {job.costCenterApprover}</p>
                        </div>
                        <p>Committed Delivery Date: {job.deliveryDate}</p>
                        <p>Test Report Distribution List: {job.distributionList}</p>
                        <p>Deposition Of Samples: {job.depositionSamples}</p>
                        {job.closingNotes ? (
                            <Alert 
                            message="Job Closing Notes"
                            description={job.closingNotes}
                            type="success"
                            showIcon
                            />
                        ) : null}
                    </div>
                </div>

                <div className="section">
                    <h3 onClick={() => toggleSection('jobDescription')} style={{ cursor: 'pointer' }}>JOB DESCRIPTION</h3>
                    {sectionVisibility.jobDescription && (
                    <>
                    <div className="job-description-tabs">
                        <div className="tabs">
                            {/* goes through each of the tabs and only shows activeTab */}
                            {['Samples', 'Purchase Orders', 'Engineering Specifications', 'Flow Chart'].map((tab, index) => (
                                <button
                                    key={index}
                                    className={`tab ${activeTab === index ? 'active' : ''}`}
                                    onClick={() => handleTabClick(index)}  //sets the index of what tab we are on
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="job-description-content">
                        {/* array indexes according to ActiveTab index */}
                        {[job.samples.map((sample, sampleIndex) => (
                                <div key={sampleIndex} className='Samples-Container'>
                                    <p>Part Name: {sample.PartName}</p>
                                    <p>Part Number: {sample.PartNumber}</p>
                                    <p>Quantity: {sample.Quantity}</p>
                                    <p>Revision Level: {sample.RevisionLevel}</p>                              
                                </div>
                            )),
                            <div><p>Order Number: {job.orderNumber}</p>
                            <p>Purchase Order Description: {job.description}</p>
                            <p>Attached File: {job.fileAddress} {job.fileAddress !== 'No File Attached' && <DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => downloadFile(job.fileAddress)}/>}</p>
                            </div>, <div>
                                <p>Engineering Specifications Description: {job.eSDescription}</p>
                                Attached Files:
                                {job.engineeringFiles.map((file, index) => (
                                    <p key={index}>
                                    File {index + 1}: {file} 
                                    {file !== 'No File Attached' && (
                                        <DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => downloadFile(file)}/>
                                    )}
                                    </p>
                                ))}
                            </div>, <div>
                            <p>Flow Chart Description: {job.fCDescription}</p>
                            <p>Attached File: {job.flowchartFile} {job.flowchartFile !== 'No File Attached' && <DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => downloadFile(job.flowchartFile)}/>}</p>
                            {/* <Table
                                columns={nodes_columns}
                                dataSource={data}
                                pagination={{
                                    pageSize: 50,
                                }}
                                scroll={{
                                    y: 200,
                                }}
                                /> */}
                            <button className='flow-chart-btn' type='button' onClick={EnterFlowChart}>View Flow Chart</button>
                        </div>].map((content, index) => (
                            <div key={index} style={{ display: activeTab === index ? 'block' : 'none' }}>
                                {/* matches the index of the array above to the tabs index we set before */}
                                {content}   
                            </div>
                        ))}
                    </div></>)}
                    <h3 onClick={() => toggleSection('quote')} style={{ cursor: 'pointer' }}>QUOTE</h3>
                    {sectionVisibility.quote && (
                        <div className="quote-section">
                            <p>Job Name: {quotation.quotationJobName}</p>
                            <p>Start Date: {quotation.quotationStartDate}</p>
                            <p>End Date: {quotation.quotationEndDate}</p>
                            <p>Hourly Cost: {quotation.hourlyCost}</p>
                            <p>Estimated ETO Hours: {quotation.etoHours}</p>
                            <p>Estimated ETO Cost: {quotation.etoCost}</p>
                            <p>Estimated Outsourcing Cost: {quotation.outsourcingCost}</p>
                            <p>Estimated Fixture Cost: {quotation.fixtureCost}</p>
                            <p>Estimated Total Cost: {quotation.totalCost}</p>
                            <p>Outsourcing Details: {quotation.outsourcingDetails}</p>
                            <p>On Completion: {quotation.quotationOnCompletion == 1 ? 'Yes' : 'No'}</p>
                            <p>Comments: {quotation.quotationComments}</p>
                        </div>
                    )}

                    {userRole !== 'Engineer' && <h3 onClick={() => toggleSection('resultsandreports')} style={{ cursor: 'pointer' }}>TESTS RESULTS AND REPORTS</h3>}
                    {sectionVisibility.resultsandreports && (
                        <div className="terms-and-conditions-section"  style={{
                            maxHeight: '23%',
                            overflowY: 'auto',
                            padding: '10px',
                            border: '1px solid #d9d9d9',
                            borderRadius: '4px'
                          }}>
                            <input type="file" multiple onChange={(e) => handleFileSelection(e, 1)} />
                            {attachedResults.length > 0 && (attachedResults[0] !== null && attachedResults[0] !== '') && (
                                <ul class="no-bullets">
                                    {attachedResults.map((file, index) => (
                                        <li  class="no-bullets" key={index} style={{ marginRight: 14}}>
                                    {approvedReports.includes(file ? (file.name ? file.name : file): null) ? 
                                    <Tag icon={<CheckCircleOutlined />} color="success" style={{marginBottom: 10}}>
                                        Approved
                                    </Tag> : (
                                    (userRole === 'Project Leader' || userRole === 'Admin') && <Button 
                                            type="primary" 
                                            onClick={(e) => {e.stopPropagation(); showConfirm(file ? (file.name ? file.name : file): null);}} 
                                            style={{ marginRight: 14, width: 60, marginBottom: 5, fontSize: '70%', padding: '2px' }}
                                            >
                                            Approve
                                        </Button>)
                                    }   
                                        {file ? (file.name ? file.name : file) : null} {file ? <DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => downloadFile(file ? (file.name ? file.name : file) : '')}/> : null}
                                        <span className='remove-file' onClick={() => removeFile(index, file ? (file.name ? file.name : file) : '', 1)}><DeleteOutlined /></span>
                                        </li>
                                    ))}
                                </ul>
                            )}
                             
                                <div style={{ position: 'relative', width: '100%' }}>
                                <TextArea 
                                    rows={4} 
                                    placeholder="Enter TIR numbers or urls" 
                                    value={tirText} 
                                    onChange={(e) => setTirText(e.target.value)}
                                />
                                <Button 
                                    onClick={() => handleTirText(tirText)} 
                                    loading={loadings[0]}
                                    style={{
                                    position: 'absolute',
                                    right: '10px',
                                    bottom: '-5px',
                                    padding: '5px 10px',
                                    fontSize: '12px',
                                    cursor: 'pointer',
                                    border: 'none',
                                    background: '#1890ff',
                                    color: 'white',
                                    borderRadius: '4px',
                                    maxWidth: '15%',
                                    zIndex: 1,
                                    }}
                                >
                                    Update
                                </Button>
                                </div>
                        </div>
                    )}

                    <h3 onClick={() => toggleSection('additionalFiles')} style={{ cursor: 'pointer' }}>ADDITIONAL FILES</h3>
                    {sectionVisibility.additionalFiles && (
                        <div className="terms-and-conditions-section" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            <input type="file" multiple onChange={(e) => handleFileSelection(e, 0)} />
                            {attachedFiles.length > 0 && (attachedFiles[0] !== null && attachedFiles[0] !== '') && (
                                <ul className='fileList'>
                                    {attachedFiles.map((file, index) => (
                                        <li key={index}>{file ? (file.name ? file.name : file) : null} {file ? <DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => downloadFile(file ? (file.name ? file.name : file) : '')}/> : null}
                                        <span className='remove-file' onClick={() => removeFile(index, file ? (file.name ? file.name : file) : '', 0)}><DeleteOutlined /></span></li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )}


                    {/* {(userRole === 'Project Leader' || userRole === 'Admin') && <h3 onClick={() => toggleSection('projectLeader')} style={{ cursor: 'pointer' }}>PROJECT LEADER SECTION</h3>}
                    {sectionVisibility.projectLeader && (
                        <div className="project-leader-section">
                            <p>Content for Project Leader </p>
                        </div>
                    )} */}
                    <h3 onClick={() => toggleSection('jobHistory')} style={{ cursor: 'pointer' }}>JOB HISTORY</h3>
                    {sectionVisibility.jobHistory && (
                        <div className="job-history-section" style={{ paddingLeft: '20px', maxHeight: '400px', overflowY: 'auto' }}>
                            <p>Job History of {job.jobOrderNumber}</p>
                            {error ? (
                                <p>Error: {error}</p>
                            ) : (
                                <Steps current={jobHistory.length - 1} direction="vertical">
                                    {jobHistory.map((item, index) => {
                                        let stepProps = {};
                                        //<SaveOutlined /> <UserAddOutlined />
                                        if ([1].includes(item.StatusChange)) {
                                            stepProps.icon = <SaveOutlined />;
                                        }
                                        if ([3].includes(item.StatusChange)) {
                                            stepProps.icon = <UserAddOutlined />;
                                        }
                                        if ([5, 7].includes(item.StatusChange)) {
                                            stepProps.icon = <CloseCircleOutlined style={{ color: 'red' }} />;
                                        }
                                        else if ([2, 4, 6, 8].includes(item.StatusChange)) {
                                            stepProps.icon = <CheckCircleOutlined style={{ color: 'green' }} />;
                                        }

                                        return (
                                            <Step key={index} title={item.title} description={
                                                <>
                                                    <p>{item.description}</p>
                                                    <p style={{ marginTop: '8px' }}>{item.time}</p>
                                                </>
                                            } {...stepProps} />
                                        );
                                    })}
                                </Steps>

                            )}
                        </div>
                    )}

                    <h3 onClick={() => toggleSection('termsAndConditions')} style={{ cursor: 'pointer' }}>TERMS AND CONDITIONS</h3>
                    {sectionVisibility.termsAndConditions && (
                        <div className="terms-and-conditions-section" style={{ maxHeight: '330px', overflowY: 'auto' }}> 
                            <p>The quotation and the services to be provided by ETO are subject to the following conditions:  
                            <ol>
                                <li>The Client will provide all necessary hardware (samples, fasteners, connectors, strikers, cables, doors, seals, glasses etc.) with proper assembly instructions and engineering support required to complete the specified testing. All testing samples and other items are required at the start of the testing.</li>
                                    
                                <li>If any item is not received, is received late, or does not function properly, ETO may make amendments to the quotation price and/or timing. Such amendments will be communicated within 1 week after all samples and other items have been received.</li>
                                    
                                <li>The Client declares that samples provided are not produced with inflammable or combustible materials at temperatures lower than or equal to 160 °C (see MAGNA material list).</li>
                                    
                                <li>All test samples and components must be delivered to ETO in production intent packaging to prevent damage during shipping. Boxes must be clearly identified with the job order number and shipped attention of Project Leader (as indicated in the job order) and Testing Manager.</li>
                                    
                                <li>ETO is not financially responsible for any damage to parts supplied by the client during testing.</li>
                                    
                                <li>A Purchase Order must be provided by the Client (or the job order must be fully approved) prior to the start of testing. Testing will start upon receipt of purchase order (or approval of job order) and delivery of all samples and required items.</li>
                                    
                                <li>Receipt of a purchase order (or approval of job order) signifies the acceptance of the cost and timing indicated by ETO.</li>
                                    
                                <li>Services will be billed monthly as costs are incurred or as otherwise set out in the quotation.</li>
                                    
                                <li>Specific consumables provided by ETO (e.g. plates for static strength test) are not included in the quotation and will be charged on completion.</li>
                                    
                                <li>The client is responsible for paying all shipping and receiving costs pertaining to this quotation and the services to be provided under it.</li>
                                    
                                <li>The client may cancel the work by means of a written request. The final cost charged will be based on the costs incurred until written request is received by ETO.</li>
                                    
                                <li>Any test fixture designs must be approved by the client before the fixtures are made.</li>
                                    
                                <li>Fixture setups must be signed off by appropriate Client personnel prior to testing.</li>
                                    
                                <li>If outsourcing is required due to circumstances beyond ETO's control or customer changes, the quoted cost is subject to change. ETO will notify the client if such a situation arises.</li>
                                    
                                <li>Additional costs may be incurred due to the nature and uncertainties of technology and research. ETO will notify the client if such a situation arises.</li>
                                    
                                <li>Any deviation required by the Client from the specified testing mentioned in this quotation could result in additional cost and timing which would be incurred by the Client. ETO will notify the client if such a situation arises.</li>
                                    
                                <li>For job orders, resources will be scheduled on a first come/first served basis.</li>
                                    
                                <li>For evaluation requests, the quoted timing is block timing only. Test resources will be scheduled and timing will be established when a Purchase Order or Job order is supplied to ETO.</li>
                                    
                                <li>The proposed end date of the work may be extended by ETO if circumstances which are beyond its control arise and cause a delay in the work. ETO will notify the client if such a situation arises.</li>
                                    
                                <li>Status for ongoing job orders will be communicated by Project Leaders at least every 2 weeks, updating the flowchart and attaching reports for completed tests.</li>
                                    
                                <li>Detailed test planning, when communicated to Client, is for information only and not mandatory for ETO. Start and finish dates of each test may be moved by ETO without any notification to the Client if the job finish date doesn’t change.</li>
                                    
                                <li>All testing will be performed as per ETO interpretations of the DVP&R and/or Engineering Specification.</li>
                                    
                                <li>Specification amendments will not be accepted after the start of testing.</li>
                                    
                                <li>Any parameters where a tolerance is not specified are assumed to be +/- 10%.</li>
                                    
                                <li>Any test requiring special equipment or extreme environmental conditions in which ETO does not have the equipment capabilities in-house will be outsourced to an external testing facility. Shipping and receiving costs and ETO travels related to outsourced tests are not included in the quotation and will be charged on completion.</li>
                                    
                                <li>Disposition of Samples: European Test Operations will store test samples "return to originator" for a period of 5 working days after test report issuing. After this time test samples will be disposed of.</li>
                            </ol>
                        </p>
                    </div>
                    )}

                {/* {userRole === 'Project Leader' && (!job.projectLeader || job.projectLeader.trim() === '') &&(
                    <button type="button" 
                            className='assignment-btn' 
                            onClick={(e) => {
                                e.stopPropagation(); 
                                handleTakeAssignment(job.jobOrderID, userName);
                            }}>
                        Take Assignment
                    </button>
                )}
                
                {userRole === 'Project Leader' && (job.projectLeader === userName ||job.projectLeader.trim()  === userName) &&( 
                    <Link to="/addjob" state={{from: "viewJob", state: job}}>
                        <button type="button" className='view-assignment-btn'>
                            View Assignment 
                        </button>
                    </Link>
                )} */}
                </div>
                
            </div>
        </div>
    );
}

export default ViewJob;

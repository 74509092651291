import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Divider,
  Input,
  Form
} from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  UserOutlined,
  CheckCircleOutlined,
  KeyOutlined
} from "@ant-design/icons";
import axios from "axios";

export const ResetPassword = (props) => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [con_pass, setConPass] = useState("");
  const [name, setName] = useState("");
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  //Confirmation Code
  const [confirmationCode, setConfirmationCode] = useState("");
  const [codeValidUntil, setCodeValidUntil] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    setIsPasswordMatch(pass === con_pass && pass.length > 0);
  }, [pass, con_pass]);

  const handle_submit = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (pass !== con_pass) {
        setErrorMessage("Passwords do not match.");
        return;
    }
    ////////// for testing, delete later
    // else{
    //     console.log("ok into the app");
    // }
    props.onResetPassword(pass);
    // try {
    //   const response = await axios.post("http://localhost:5001/resetpass", {
    //     email,
    //     password: pass
    //   });
      

    //   if (response.data.success) {
    //     // Store token and expiration time in local storage
    //     const expirationTime = new Date().getTime() + 10000; // 10 secs
    //     localStorage.setItem('token', response.data.token);
    //     localStorage.setItem('expirationTime', expirationTime);
    //     // console.log("register jsx, email is:", email);
    //     console.log("register jsx, the email is:", email);
    //     console.log("register jsx, the confirmationCode is:", confirmationCode);
    //     props.onRegister(email, confirmationCode, codeValidUntil);
    //   } else {
    //     console.log(response.data.message);
    //     setErrorMessage(response.data.message);
    //     // alert(response.data.message);
    //   }
    // } catch (error) {
    //   console.log(error.message);
    //   setErrorMessage(error.message);
    // //   alert(error.message);
    // }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const expirationTime = localStorage.getItem('expirationTime');

    // Check if token and expiration time are both valid
    if (token && expirationTime && new Date().getTime() < expirationTime) {
      props.onLogin();
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('expirationTime');
    }
  }, []);


  return (
    <div className="register-container">
      <Card className="auth-form-card">

        <form className="register-form" onSubmit={handle_submit}>

          
          <label htmlFor="password" className="form-label" >
          Please Set Your New Password:
          </label>
          
          <Input.Password
            value={pass}
            prefix={<KeyOutlined />}
            onChange={(e) => setPass(e.target.value)}
            type="password"
            placeholder="New Password"
            id="password"
            name="password"
            className="form-input"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          <Input.Password
            value={con_pass}
            prefix={<KeyOutlined />}
            onChange={(e) => setConPass(e.target.value)}
            type="password"
            placeholder="Confirm Password"
            id="con_password"
            name="con_password"
            className="form-input"
            iconRender={(visible) =>
              isPasswordMatch
                ? <CheckCircleOutlined style={{ color: "green" }} />
                : (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)
            }
          />
        {errorMessage && 
            (<div className="error-message">{errorMessage}</div>
        )}
          <Button className="submit-btn" type="primary" onClick={handle_submit}>
            Confirm
          </Button>
        </form>

        
      </Card>
    </div>
  );
};


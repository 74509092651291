import React, { useState, useEffect } from "react";
import { Card, Row, Col, Switch, Avatar, Typography, Divider } from "antd";
import { UserOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

export const Profile = () => {
  const userRole = localStorage.getItem('userRole');
  const userName = localStorage.getItem('userName');
  const userEmail = localStorage.getItem('userEmail');

  const [isChecked, setIsChecked] = useState(userRole === 'Engineer');

  useEffect(() => {
    setIsChecked(userRole === 'Engineer');
  }, [userRole]);

  const handleSwitchChange = (checked) => {
    if (checked) {
      localStorage.setItem('userRole', 'Engineer');
      localStorage.setItem('originalRole', userRole);
    } else {
      localStorage.setItem('userRole', localStorage.getItem('originalRole'));
    }
    setIsChecked(checked);
  };

  return (
    <div style={{ padding: "30px", backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
      <Card 
        title={<Title level={3}>My Account</Title>} 
        bordered={false} 
        style={{ maxWidth: 900, margin: "auto", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
      >
        <Row gutter={[16, 16]} align="middle">
          <Col span={6} style={{ textAlign: "center" }}>
            <Avatar size={100} icon={<UserOutlined />} />
            <div style={{ marginTop: 10 }}>
              <Title level={4}>{userName || "User"}</Title>
              <Text type="secondary">{userRole || "No Role Assigned"}</Text>
            </div>
          </Col>
          <Col span={10}>
            {userRole !== 'Test Technician' && (
              <div style={{ marginBottom: 20 }}>
                <Switch
                  checkedChildren="Originator Mode"
                  unCheckedChildren="Originator Mode"
                  onChange={handleSwitchChange}
                  checked={isChecked}
                  className="switch-container"
                  style={{ marginBottom: 10 }}
                />
              </div>
            )}
            
            <Row gutter={[20, 20]} style={{ marginBottom: 10 }}>
              <Col span={6}>
                <Text strong><MailOutlined style={{ marginRight: 8 }} />Email:</Text>
              </Col>
              <Col span={18}>
                <Text>{userEmail}</Text>
              </Col>
            </Row>

            <Row gutter={[16, 8]} style={{ marginBottom: 10 }}>
              <Col span={6}>
                <Text strong><SettingOutlined style={{ marginRight: 8 }} />Role:</Text>
              </Col>
              <Col span={18}>
                <Text>{userRole}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Profile;

import React, { useState, useEffect } from "react";
import { Button, Card, Divider, Input } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone, UserOutlined, CheckCircleOutlined, MailOutlined, KeyOutlined } from "@ant-design/icons";
import axios from "axios";

export const Register = (props) => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [con_pass, setConPass] = useState("");
  const [name, setName] = useState("");
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Confirmation Code
  const [confirmationCode, setConfirmationCode] = useState("");
  const [codeValidUntil, setCodeValidUntil] = useState(null);

  useEffect(() => {
    setIsPasswordMatch(pass === con_pass && pass.length > 0);
  }, [pass, con_pass]);

  const validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const isValidLength = password.length >= 6;

    return hasUpperCase && hasLowerCase && hasNumber && isValidLength;
  };

  const handle_submit = async (e) => {
    e.preventDefault();

    // Confirmation Code generation
    const confirmationCode = Math.random().toString(36).substr(2, 6).toUpperCase();
    setConfirmationCode(confirmationCode);
    setCodeValidUntil(Date.now() + 600000); // valid for 10 minutes

    // Check if any field is empty
    if (!email || !name || !pass || !con_pass) {
      setErrorMessage("Please fill in all fields.");
      return;
    }
    // Validate if the email ends with '@magna.com'
    if (!email.endsWith('@magna.com')) {
      setErrorMessage('Email must end with @magna.com');
      return;
    }
    // Check if passwords match
    if (pass !== con_pass) {
      setErrorMessage("Passwords do not match.");
      return;
    }
    // Validate password strength
    if (!validatePassword(pass)) {
      setErrorMessage("Password must be at least 6 characters long and include at least one uppercase letter, one lowercase letter, and one number.");
      return;
    }

    try {
      const response = await axios.post("https://zwewaetojsbe.azurewebsites.net/register", {
        name,
        email,
        password: pass,
        confirmationCode
      });

      if (response.data.success) {
        // Store token and expiration time in local storage
        const expirationTime = new Date().getTime() + 10000; // 10 secs
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('expirationTime', expirationTime);
        console.log("register jsx, the email is:", email);
        console.log("register jsx, the confirmationCode is:", confirmationCode);
        props.onRegister(email, confirmationCode, codeValidUntil);
      } else {
        console.log(response.data.message);
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      console.log(error.message);
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const expirationTime = localStorage.getItem('expirationTime');

    // Check if token and expiration time are both valid
    if (token && expirationTime && new Date().getTime() < expirationTime) {
      props.onLogin();
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('expirationTime');
    }
  }, []);

  return (
    <div className="register-container">
      <Card className="auth-form-card">
        <img className="logo-img" src="./magna-logo-colored.png" alt="Logo" />
        <Divider className="register-divider">ETO Register</Divider>
        <form className="register-form" onSubmit={handle_submit}>
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <Input
            prefix={<MailOutlined />}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="firstname.lastname@magna.com"
            id="email"
            name="email"
            className="form-input"
          />
          <br></br>
          <label htmlFor="name" className="form-label">
            Account name
          </label>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="name"
            placeholder="Full Name"
            id="name"
            name="name"
            prefix={<UserOutlined className="site-form-item-icon" />}
            className="form-input"
          />
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <Input.Password
            value={pass}
            prefix={<KeyOutlined />}
            onChange={(e) => setPass(e.target.value)}
            type="password"
            placeholder="**********"
            id="password"
            name="password"
            className="form-input"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
          <Input.Password
            value={con_pass}
            prefix={<KeyOutlined />}
            onChange={(e) => setConPass(e.target.value)}
            type="password"
            placeholder="Confirm Password"
            id="con_password"
            name="con_password"
            className="form-input"
            iconRender={(visible) =>
              isPasswordMatch
                ? <CheckCircleOutlined style={{ color: "green" }} />
                : (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)
            }
          />
          {errorMessage && (
            <div className="error-message">{errorMessage}</div>
          )}
          <Button className="submit-btn" type="primary" onClick={handle_submit}>
            Register
          </Button>
          <Button
            className="login-btn"
            onClick={() => props.onFormSwitch("login")}
          >
            Already have an account? Login Here
          </Button>
        </form>
      </Card>
    </div>
  );
};

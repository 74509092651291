import React from 'react';
import "./NavigationPanel.css";
import {Link} from 'react-router-dom';
import {Button} from 'antd';
import  {RollbackOutlined } from '@ant-design/icons';



function NavigationPanel({ currentStep, onStepClick }) {
  const handleStepClick = (step) => {
    if (onStepClick) {
      onStepClick(step);
    }
  };
  const userRole = localStorage.getItem('userRole');

  return (
    <div className="navigation-panel">
     
      <div className="step-description">
        <div className="navigation-back-button">
            <Link to="/">
              <Button className='quotation-cancel' danger style={{width: '24%'}}>
                <RollbackOutlined />
              </Button>
            </Link>
        </div>
        
        <div
          className={`step-description-item ${currentStep === 1 ? 'active' : ''}`}
          onClick={() => handleStepClick(1)}
        >
          General Info
        </div>
        <div
          className={`step-description-item ${currentStep === 2 ? 'active' : ''}`}
          onClick={() => handleStepClick(2)}
        >
          Job Description
        </div>
        <div
          className={`step-description-item ${currentStep === 3 ? 'active' : ''}`}
          onClick={() => handleStepClick(3)}
        >
          Test Instructions
        </div>
        {(userRole === 'Project Leader' || userRole === 'Cost Center Manager' || userRole === 'Admin') && (<div
          className={`step-description-item ${currentStep === 6 ? 'active' : ''}`}
          onClick={() => handleStepClick(6)}
        >
          Quotation
        </div>)}
      </div>
    </div> 
  );
}

export default NavigationPanel;
 
import React, { useState, createRef, useEffect } from 'react';
import './resetPages.css';
import { Form, Input, Button, Card } from "antd";
import { UserOutlined } from "@ant-design/icons";
import {
    useNavigate
  } from "react-router-dom";

import CodeInput from "./CodeInput"; 


export const ResetVerification = ({ onVerify, onResend }) => {
  const [form] = Form.useForm();
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const handleVerify = () => {
    onVerify(code);
  };
  const handleResend = () => {
    onResend();
    form.resetFields();
    setCode("");
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card style={{ width: "450px" }}>
        <Form form={form} layout="vertical" onFinish={handleVerify}>
          <Form.Item
            label="A Confirmation Code is sent to your Magna Email"
            name="code"
            rules={[
              {
                required: true,
                message: "Please input your confirmation code!",
              },
            ]}
          >
            <CodeInput length={6} onChange={(newCode) => setCode(newCode)} />
          </Form.Item>
          <Form.Item>
            <Button
              className="submit-btn"
              style={{ width: "200px" }}
              type="primary"
              htmlType="submit"
              block
            >
              Verify
            </Button>
            <Button className="register-btn" style={{ width: "200px" }} onClick={handleResend} block>
              Resend
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default ResetVerification;

// ContextMenu.jsx
import React from 'react';
import { Menu, Dropdown } from 'antd';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

const ContextMenu = ({ onDelete, onDownload }) => {
  return (
    <Menu>
      <Menu.Item key="delete" icon={<DeleteOutlined />} onClick={onDelete}>
        Delete Node
      </Menu.Item>
      <Menu.Item key="download" icon={<DownloadOutlined />} onClick={onDownload}>
        Download Test Instructions
      </Menu.Item>
    </Menu>
  );
};

export default ContextMenu;


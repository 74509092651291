import React, { useState, useEffect } from 'react';
import "./SideBar.css"
import Select from 'react-select';
import { Tooltip } from 'antd';
import axios from '../axiosConfig';
import { customFetch } from '../customFetch';
// const PY_API_URL = "https://aztowaetopybackend.azurewebsites.net";
// const PY_API_URL = "http://localhost:5000/";
const PY_API_URL = 'https://zwewaetopybe.azurewebsites.net';

export default () => {
  const [tests, setTests] = useState([]);
  const [testsWithPath, setTestsWithPath] = useState({});
  const [selectedTests, setSelectedTests] = useState([]);
  const [testNames, setTestNames] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    const dropdown = document.querySelector('.test-dropdown');
    setTimeout(() => {
      dropdown.style.animation = 'none';
      dropdown.style.outline = '1.5px solid orange';
    }, 4500);
  }, []);

  //gets the tests' names
  useEffect(() => {
    customFetch(`${PY_API_URL}/api/get_test_names`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setTestNames(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);
  // useEffect(() => {
  //   const fetchTestNames = async () => {
  //     try {
  //       const response = await axios.get('/api/get_test_names');
  //       setTestNames(response.data); 
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };
  
  //   fetchTestNames();
  // }, []);

  //gets all the tests from Python
  useEffect(() => {
    customFetch(`${PY_API_URL}/api/get_all_tests`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const allTestNames = [];
        const allTestsWithExtension = {};
        data.forEach(test => {
          let testNum = test? test.substring(0,10): null; 
          allTestNames.push(testNum);
          allTestsWithExtension[testNum] = test
        });
        setTestsWithPath(allTestsWithExtension);
        setTests(allTestNames);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  //occurs when a node is starting to be dragged
  const onDragStart = (event, test) => {
    if (test === 'custom_node') {
      event.dataTransfer.setData('application/reactflow', JSON.stringify({ type: 'custom_node' }));
    } else {
      // const testSubstring = test ? test.substring(0, 10): null;
      event.dataTransfer.setData('application/reactflow', JSON.stringify({ type: 'testDetailer', testNumber: testsWithPath[test.substring(0, 10)], testName: testNames }));
    }
    event.dataTransfer.effectAllowed = 'move';
  };
  

  // handle the removal of a selected test
  const removeSelectedTest = (testToRemove) => {
    const updatedSelectedTests = selectedTests.filter((test) => test !== testToRemove);
    setSelectedTests(updatedSelectedTests);
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     const options = tests
  //       .filter(test => test.toLowerCase().includes(filterText.toLowerCase()))
  //       .map(test => ({ value: test + '_' + testNames[test.substring(0, 6)], label: test + '_' + testNames[test.substring(0, 6)] }));
  //     setFilteredOptions(options);
  //   }, 300);
  //   return () => clearTimeout(timer);
  // }, [filterText, tests, testNames]);
  

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     try {
  //       const options = tests
  //         .filter(test => test.toLowerCase().includes(filterText.toLowerCase()))
  //         .map(test => {
  //           const testNameKey = test.substring(0, 6);
  //           const testName = testNames[testNameKey];
  //           if (!testName) {
  //             throw new Error(`Test name for key ${testNameKey} not found`);
  //           }
  //           return { value: test + '_' + testName, label: test + '_' + testName };
  //         });
  //       setFilteredOptions(options);
  //     } catch (error) {
  //       console.error('Error processing tests:', error);
  //       setFilteredOptions([]); // Set to empty array or handle error state appropriately
  //     }
  //   }, 300);
  
  //   return () => clearTimeout(timer);
  // }, [filterText, tests, testNames]);

  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        const options = tests
          .filter(test => test.toLowerCase().includes(filterText.toLowerCase()))
          .map(test => {
            const testNameKey = test.substring(0, 6);
            const testName = testNames[testNameKey];
            if (testName === null || testName === undefined) {
              throw new Error(`Test name for key ${testNameKey} not found`);
            }
            if (testName === '') { 
              return {value: test, label: test};
            };
            return { value: test + '_' + testName, label: test + '_' + testName };
          });
  
        // Prepend the custom node option
        const customNodeOption = { value: 'custom_node', label: 'Custom Node' };
        const updatedOptions = [customNodeOption, ...options];
  
        setFilteredOptions(updatedOptions);
      } catch (error) {
        console.error('Error processing tests:', error);
        setFilteredOptions([]); // Set to empty array or handle error state appropriately
      }
    }, 300);
  
    return () => clearTimeout(timer);
  }, [filterText, tests, testNames]);
  
  

  return (
    <aside>
      <div className="description">You can drag and drop the tests to the right</div>

      {/* Dropdown for selecting a test */}
      <Select
        className='test-dropdown'
        options={filteredOptions}
        isSearchable
        placeholder="Type to filter tests"
        onChange={(selectedOption) => {
          const value = selectedOption.value;
          if (!selectedTests.includes(value)) {
            setSelectedTests([...selectedTests, value]);
          }
        }}
      />
      <div className="selected-tests-container">
        {selectedTests.map((test) => (
          <div key={test} className="test-container">
            <div className="dndnode input" onDragStart={(event) => onDragStart(event, test)} draggable>
              <button className='test-remove-btn' onClick={() => removeSelectedTest(test)}>X</button>
              <Tooltip className='testname-sidebar' title={test} mouseEnterDelay={0.8}>
                <span>{test}</span>
              </Tooltip>
              {/* <Tooltip className='testname-sidebar' title={test} mouseEnterDelay={0.8}>
                <span style={{ paddingRight: `${test.length < 28 ? (34 - test.length) : 0}ch` }}>{test}</span>
              </Tooltip> */}
            </div>
          </div>
        ))}
      </div>


      {/* <div className="dndnode" onDragStart={(event) => onDragStart(event, 'default')} draggable>
        <select name='' value='' onChange={(e) => {selectedTests.includes(e.target.value) ? setSelectedTests([...selectedTests]) : setSelectedTests([...selectedTests, e.target.value])}}>
        <option value="" disabled selected>Select a test</option>
        {tests.map((test, index) => (
          <option key={index} value={test}>
            {test}
          </option>
        ))}
      </select>
      </div> */}
        
    </aside>
  );
};
